<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on" v-if="!button">
        <v-list-item-title>Remote Order Link</v-list-item-title>
      </v-list-item>
      <v-btn v-else small v-on="on" color="success">Order Link</v-btn>
    </template>

    <v-card>
      <ModelTitle title="Remote Ordering" @close="dialog = false" />
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card dark flat color="text--white">
              <v-card-title>
                <span>Copy This Link To Share</span>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="copyLink" fab v-on="on">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy link</span>
                </v-tooltip>
                <v-tooltip bottom v-if="showShare">
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="shareLink" fab v-on="on">
                      <v-icon>mdi-share</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy link</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text>
                <span style="color: white"> {{ url }} </span>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-row justify="end">
                  <v-col class="shrink">
                    <v-dialog v-model="textMessagesDialog" width="300">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark color="#F0C316" small v-bind="attrs" v-on="on">
                          Send Text Message
                        </v-btn>
                      </template>

                      <v-card>
                        <ModelTitle title="Send Message" @close="textMessagesDialog = false" />

                        <v-card-text>
                          <v-text-field class="centered-input" type="tel" outlined dense hide-details autofocus
                            label="Cell Number" v-model="cellNumber"></v-text-field>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="sendTextMessage">
                            Send
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col class="shrink"><whats-app :url="url" title="smplsale link" scale="2"></whats-app></v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- <div class="title">Copy This URL To Share</div>
        <div class="mt-3">{{ url }}</div>
        <v-btn rounded @click="copyLink" color="info">Copy</v-btn> -->
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import copy from "copy-to-clipboard";
// import Hashids from "hashids";
import { mapGetters } from "vuex";
// const hashids = new Hashids();

import { WhatsApp } from "vue-socialmedia-share";
// import remoteOrderService from '@/modules/Order/Remote/service.js'
import Hashids from "hashids";
const hashids = new Hashids();

import orderService from "@/modules/Order/RemoteOrderView/service.js";

export default {
  props: ["id", "customer", "globalLink", "button", "productId"],
  data() {
    return {
      cellNumber: null,
      textMessagesDialog: false,
      token: null,
      showShare: false,
      url: "Creating URL Please wait...",
      dialog: null,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  watch: {
    dialog(val) {
      if (val) {
        // const hashId = hashids.encode(this.id);
        this.createToken();
        // this.url = btoa("category=textile&user=user1")
        // this.urlc = atob(this.url)
      }
    },
  },
  components: {
    WhatsApp,
  },
  created() {
    if (navigator.share) {
      this.showShare = true;
    } else {
      this.showShare = false;
    }
  },
  methods: {
    sendTextMessage() {
      // console.log("send test messetes", this.cellNumber);
      const obj = {
        phoneNumber: this.cellNumber,
        url: this.url,
        customerName: this.customer.name,
      };

      // console.log("al;sdkjf;asdklf", obj);

      orderService
        .publicSendTextMessage(this.currentBusiness.code, obj)
        .then(() => {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "success",
              title: "Messages Send! ",
            });
        });

      this.textMessagesDialog = false;
      this.dialog = false
    },
    createToken() {
      const hashId = hashids.encode(this.id);
      const busCode = hashids.encode(this.currentBusiness.code);
      const productId = hashids.encode(this.productId);
      // console.log(
      //   "creating token",
      //   `http://app.smplsale/o/${busCode}-${hashId}`
      // );
      if (this.globalLink) {
        if (this.productId) {
          this.url = `${process.env.VUE_APP_FE_URL}/public/order/${busCode}-${productId}`;
        } else {
          this.url = `${process.env.VUE_APP_FE_URL}/public/order/${busCode}`;
        }
      } else {
        this.url = `${process.env.VUE_APP_FE_URL}/o/${busCode}-${hashId}`;
      }
      
    },
    shareLink() {
      if (navigator.share) {
        navigator
          .share({
            title: this.currentBusiness.name,
            text: this.text,
            url: this.url,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing:", error));
      }
      this.dialog = false;
    },
    copyLink() {
      copy(this.url);
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        })
        .fire({
          icon: "success",
          title: "Link is copy!",
        });

      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
